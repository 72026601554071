import React from 'react';
import './components.css';

const NavContactInfo = () => {
	return (
		<div className='sub-nav-properties nav-contact'>
			<span className='nav-contact-info'><a href="tel:+77779500050">+7 777 950 00 50</a></span>
		</div>
)
};

export default NavContactInfo; 
