import React from 'react';
import './Hero.css';
// import HeroBlock from './components/HeroBlock'

const Hero = () => {
	return (
		<div className='hero-container'>
		{/* <HeroBlock /> */}
		</div>
)
};

export default Hero;
