import React, { Component } from 'react';
import './ServicesMain.css';

const ServicesMain = () => {
	return (
		<div className='services-main-container'>
			<h1>Тарифы на доставку в Республике Казахстан</h1>
			<p>Транспортная компания Beta Express предлагает Вашему вниманию услуги по экспресс доставке отправлений по Казахстану, включая самые отдаленные уголки страны.</p>
			<p>Вы можете ознакомиться с тарифами перейдя по <a target="_blank" href="https://drive.google.com/file/d/0B79RWMjHwqGBUkNYMVhsaHJVNDVWZTVtNTl5cEItOTBweDFF/view?usp=sharing">ссылке.</a></p>

      <h1>Правила упаковки</h1>
			<p>Перед тем как передать оправления курьеру Beta Express, убедитесь, что они правильно упакованы. Это будет отличным дополнением ко всем мерам сохранности внутреннего вложения отправлений. Все, что для этого нужно — следовать нескольким правилам, указанным ниже.</p>
			{/* <p><a className='services-main-a' href="https://drive.google.com/file/d/1fFS40_LhLBFDIFjmyDnrRZQy8_oSC7NO/view?usp=sharing">Открыть коммерческое предложение</a></p> */}
			<p><strong>Клейте адресный ярлык</strong></p>
			<p>Обязательно укажите контактные данные на каждом из отправлений. Посылки путешествуют тысячи километров перед тем, как попасть к адресату и поэтому наличие адресного ярлыка будет полезной визитной карточкой отправления в этом долгом пути.</p>
			<p><strong>Используйте правильный упаковочный материал</strong></p>
			<p>Для документов, компания Beta Express предоставляет специальные защитные конверты бесплатно. Для посылок мы предлагаем дополнительную упаковку ваших отправлений в специальные гофро-коробки. Эта услуга стоит 400 тенге и позволит повысить сохранность вложимого. Если же вы хотите произвести упаковку самостоятельно, то постарайтесь упаковать отправление плотно в картонную коробку, чтобы оно не болталось внутри во время пересылки. Не используйте полиэтиленовые пакеты, мешки и сумки в качестве упаковки, так как это усложняет пересылку и повышает риск повреждения отправлений. Все возможные места вскрытия нужно закрыть скотчем.</p>
			<p><strong>Используйте маркировку</strong></p>
			<p>При пересылке мы не знаем, что находится внутри каждого отправления. А это может быть хрупкий хрусталь или вещь, которую нельзя ставить другой стороной. Обязательно маркируйте такие отправления, чтобы наши сотрудники уделяли особое внимание такому грузу.</p>
		</div>
)
};

export default ServicesMain;
