import React, { Component } from 'react';
import './ContactMain.css';

const ContactMain = () => {
	return (
		<div className='contact-main-container'>
			<div>
				<h2>Головной офис</h2>
				<a href="https://yandex.ru/maps/?um=constructor%3Ab5cb1aed0a9f9a603a31a1c2dde7d442080fe9c7e5a70dd998da278f9f6d0cf8&amp;source=constructorStatic" target="_blank"><img className='contact-map' src="https://api-maps.yandex.ru/services/constructor/1.0/static/?um=constructor%3Ab5cb1aed0a9f9a603a31a1c2dde7d442080fe9c7e5a70dd998da278f9f6d0cf8&amp;width=600&amp;height=450&amp;lang=ru_RU" alt="" style={{border: 0}} /></a>
			</div>

			<div className='contact-p-req'>
				<h2>Наши контакты и реквизиты</h2>
				<p>Юр.адрес: г.Алматы, Жетысуский район, ул. Ниязбекова, дом 33, почтовый индекс 050034</p>
				<p>Факт.адрес: г.Алматы, Жетысуский район, ул. Ниязбекова, дом 33, почтовый индекс 050034</p>
				<p>Тел. 8 777 950 00 50 , факс: 227-32-24</p>
				<p>Эл.адрес: betaex@mail.ru</p>
				<p>РНН 600700677448</p>
				<p>БИН 130 340 000 650</p>
				<p>ИИК KZ 368560000005765898</p>
				<p>АГФ АО «Банк ЦентрКредит»</p>
				<p>БИК KCJBKZKX</p>
				<p>Директор: Шайхиева Майра Талгатовна</p>
			</div>
		</div>
)
};

export default ContactMain;

